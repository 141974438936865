span.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.css-18lrjg1-MuiCircularProgress-root,
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-q8hpuo-MuiFormControl-root .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #8c57ff;
}

/* Login */
.login-container {
    width: 450px;
    background: white;
    margin: 0 auto;
    color: rgba(76, 78, 100, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    background-image: none;
    overflow: hidden;
    box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px;
    z-index: 1;
}

.login-main {
    display: flex;
    min-height: 100vh;
    width: 100%;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 1.25rem;
}

.MuiCardContent-root.card-content {
    padding: 32px 28px;
}

.card-content h5 {
    font-family: inherit;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
    letter-spacing: 0.18px;
    color: rgba(76, 78, 100, 0.87);
}

.card-content p {
    font-family: initial;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: rgba(76, 78, 100, 0.68);
}

.login-content {
    padding: 24px 0;
}

.loginbg {
    bottom: 10%;
    right: 0;
    width: 100%;
    position: absolute;
}

.LabelWidth {
    width: unset;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.submit-btn {
    margin-top: 16px !important;
    background-color: #8c57ff !important;
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    padding: 8px 22px;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    border: unset;
    cursor: pointer;
}

.table-body td svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: #8c57ff;
    cursor: pointer;
}